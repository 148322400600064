<template>
	<div class="my-order">
		<div class="order-ways rcc">
			<el-radio-group v-model="navOrder">
				<el-radio-button label="物流订单"></el-radio-button>
				<el-radio-button label="商城订单"></el-radio-button>
			</el-radio-group>
		</div>

		<div class="order-wrap" v-show="navOrder == '物流订单'">
			<el-tabs v-model="activeName" type="card" @tab-click="handleSubMenu">
				<el-tab-pane label="全部订单" name="全部订单" />
				<el-tab-pane label="已寄出" name="已寄出" />
				<el-tab-pane label="已到货" name="已到货" />
			</el-tabs>

			<div class="table-wrap">
				<div class="search-box rsc">
					<span style="font-weight: bold;">订单号</span>
					<div style="width: 400px; margin-left: 30px">
						<el-input placeholder="请输入查询单号" v-model="orderNo" clearable class="input-with-select">
							<el-button slot="append" icon="el-icon-search"></el-button>
						</el-input>
					</div>
				</div>

				<div class="order-table">
					<div class="theader rsc">
						<div class="col_1">订单信息</div>
						<div class="col_2">联系人</div>
						<div class="col_3">物流状态</div>
						<div class="col_4">价格</div>
						<div class="col_5">订单状态</div>
						<div class="col_6">操作</div>
					</div>

					<div class="tbody">
						<el-empty v-if="list.length" :image-size="200"></el-empty>


						<div class="order-item" v-else v-for="(item, index) in 3" :key="index">
							<div class="tr-title rbc">
								<div>
									<span style="margin-right: 30px; font-size: .92rem;">订单号：23423423423423</span>
									<span>下单日期：2021-10-16</span>
								</div>
								<span>删除订单</span>
							</div>

							<div class="tr-cont rsc">
								<div class="col_1 cbc" style="height: 100%;">
									<div class="col_1-top rcc">
										<div class="top-left"><span>反倒是发</span></div>
										<i class="el-icon-right" style="font-size: 1.4rem;"></i>
										<div class="top-right"><span>反倒是发送到发送到发斯蒂芬</span></div>
									</div>
									<div class="col_1-bottom cac">
										<div>超凡物流</div>
										<span style="color: #828282">客运</span>
									</div>
								</div>
								<div class="col_2">张三</div>
								<div class="col_3">运输中</div>
								<div class="col_4" style="color: #E25053">￥200.00</div>
								<div class="col_5">已付款</div>
								<div class="col_6 cac" style="height: 50%; color: #5288F5; cursor: pointer;">
									<span>查看物流</span>
									<span>查看订单</span>
								</div>
							</div>
						</div>

					</div>

					<div class="pagination">
						<el-pagination background layout="prev, pager, next" :total="1000" />
					</div>
				</div>


			</div>
		</div>
		<div class="order-wrap" v-show="navOrder == '商城订单'">
			<el-tabs v-model="activeName1" type="card" @tab-click="handleSubMenu1">
				<el-tab-pane label="全部订单" name="全部订单" />
				<el-tab-pane label="待支付" name="待支付" />
				<el-tab-pane label="待发货" name="待发货" />
				<el-tab-pane label="待收货" name="待收货" />
				<el-tab-pane label="已完成" name="已完成" />
			</el-tabs>

			<div class="table-wrap">
				<div class="search-box rsc">
					<span style="font-weight: bold;">订单号</span>
					<div style="width: 400px; margin-left: 30px">
						<el-input @change="orderLst" placeholder="请输入查询单号" v-model="params.order_id" clearable class="input-with-select">
							<el-button @click="orderLst" slot="append" icon="el-icon-search"></el-button>
						</el-input>
					</div>
				</div>

				<div class="order-table">
					<div class="theader rac">
						<div class="col_1">订单信息</div>
						<div class="col_2">单价</div>
						<div class="col_3">数量</div>
						<div class="col_4">订单状态</div>
						<div class="col_6">操作</div>
					</div>

					<div class="tbody">
						<el-empty v-if="list.length==0" :image-size="200"></el-empty>

						<div v-else class="order-item" v-for="(item, index) in list" :key="index">
							<div class="tr-title rbc">
								<div>
									<span style="margin-right: 30px; font-size: .92rem;">订单号：{{item.order_id}}</span>
									<span>下单日期：{{item.createTime}}</span>
								</div>
								<a-popconfirm title="你确定要删除订单吗?" @confirm="() => onDelete(item.order_id)">
									<a v-if="item._status!=('待发货'||'待收货')" href="javascript:;">删除订单</a>
								</a-popconfirm>
							</div>

							<div class="tr-cont rac">
								<div class="col_1 ccc df fdc" style="height: 100%;">
									<div v-for="item2 in item.product" style="width: 100%;margin-top: 10px;"
										class="df aic">
										<img style="width: 77px;height: 77px;margin-right: 10px;" :src="item2.image">
										<p class="text_over_2line">{{item2.product_name}}</p>
									</div>
								</div>
								<div class="col_2" style="color: #E25053">{{item.pay_price}}￥</div>
								<div class="col_3">x{{item.total_num}}</div>
								<div class="col_4">{{item._status}}</div>
								<div class="col_6 cac" style="height: 50%; color: #5288F5; cursor: pointer;">
									<span v-if="item.status==4">查看物流</span>
									<span v-if="item.status==4">确认收货</span>
									<span @click="cancle(item.order_id)" v-if="item.status==1">取消订单</span>
									<span @click="repay(item.order_id)" v-if="item.status==1">重新支付</span>
									<span @click="dialogVisible=true,orderDetail(item.order_id)">查看订单</span>
								</div>
							</div>
						</div>
					</div>

					<div class="pagination">
						<el-pagination @current-change="changePage" :page-size="params.limit" background
							layout="prev, pager, next" :total="total" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data: () => ({
			dialogVisible: false,
			navOrder: '物流订单',
			activeName: '全部订单',
			activeName1: '全部订单',
			orderNo: '', // 查询物流订单号
			list: [],
			total: null,
			params: {
				order_id:'',
				status: 0,
				page: 1,
				limit: 3
			}
		}),
		watch: {
			navOrder(val) {
				if (val == '商城订单') {
					this.orderLst()
				}
			}
		},
		mounted() {},
		methods: {
			async orderDetail(order_id) {
				let {result}=await this.$api.orderDetail(order_id)
				const h = this.$createElement;
				this.$info({
					title: '订单详情',
					content: h('div', {}, [
						h('p', `名称：${result.pay_body}`),
						h('p', `价格：${result.product_money}`),
						h('p', `状态：${result._status}`),
						h('p', `订单号：${result.order_id}`),
					]),
				})
				
			},
			async cancle(order_id) {
				await this.$api.cancelOrder(order_id)
				this.$ok('取消成功')
				this.orderLst()
			},
			async repay(id) {
				let {
					pay,
					order_id
				} = await this.$api.orderRepay(id)
				if (!pay) return this.$fail('订单已经过期')
				pay = pay.substring(pay.lastIndexOf('/') + 1, pay.length)
				this.$go(`/payOrder?pay=${pay}&order_id=${order_id}`)
			},
			async onDelete(order_id) {
				await this.$api.delOrder(order_id)
				this.$ok('删除成功')
				this.orderLst()
			},
			changePage(current) {
				this.params.page = current
				this.orderLst()
			},
			async orderLst() {
				let {
					list,
					total
				} = await this.$api.orderLst(this.params)
				this.list = list
				this.total = total
			},
			handleSubMenu(tab) {
				console.log(tab);
				this.activeName = tab.label
			},
			handleSubMenu1(tab) {
				this.activeName1 = tab.label
				this.params.page = 1
				let index = Number(tab.index)
				if (index > 1) index++
				this.params.status = index
				this.orderLst()
			},
		}
	}
</script>

<style lang="scss" scoped>
	.my-order {
		/deep/ .el-radio-button__inner {
			width: 200px;
			font-size: 1rem;
		}

		.order-wrap {
			padding: 20px;

			.table-wrap {
				height: 760px;
			}

			.order-table {
				margin-top: 20px;
				height: 700px;

				.col_1 {
					width: 30%;

					.col_1-top {
						width: 100%;
						height: 50%;

						.top-left,
						.top-right {
							width: 45%;
							display: table;
							padding: 0 5px;
							font-size: .96rem;
							font-weight: bold;

							span {
								display: table-cell;
								vertical-align: middle;
							}
						}

						.top-left {
							text-align: right;
						}

						.top-right {
							text-align: left;
						}
					}

					.col_1-bottom {
						height: 50%;
						padding-bottom: 10px;
					}
				}

				.col_2 {
					width: 22%;
				}

				.col_3 {
					width: 10%;
				}

				.col_4 {
					width: 13%;
				}

				.col_5 {
					width: 12%;
				}

				.col_6 {
					width: 13%;
				}

				.theader {
					background: #F9F9F9;
					line-height: 45px;
					font-size: .95rem;
					text-align: center;
				}

				.tbody {
					width: 100%;
					height: 76%;
					text-align: center;

					.order-item {
						margin: 10px 0;
						font-size: .95rem;
						border: 1.8px solid #8BBBEB;
					}

					.tr-title {
						width: 100%;
						background: #F2F9FF;
						padding: 15px 45px 15px 20px;
					}

					.tr-cont {
						padding-bottom: 10px;
						// background: blue;
					}
				}
			}
		}
	}

	.pagination {
		text-align: center;
		margin: 40px auto;
	}
</style>
